body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #ffffff;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
a,
th,
td {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

/**
  * Navbar
  */

.header {
    height: 8vh;
    /* margin: 6px 0px; */
    min-height: 26px;
    display: flex;
    margin-top: 5px;
    background-color: #ffffff;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    width: 90%;
    height: 100%;
    top: 0;
}

.logo {
    margin-right: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
}

.nav-link {
    color: #333333;
    margin-right: 1rem;
    cursor: pointer;
    text-decoration: none;
}

.nav-link:hover {
    color: #666666;
}

.dropdown-menu {
    margin-top: 4px !important;
}

.menu-item {
    color: #666666;
    font-size: 0.8rem;
    margin-top: 2px;
}

.menu-item:hover {
    color: #383838;
}

.upgrade-btn {
    border: 1px solid rgba(128, 0, 246, 0.75) !important;
    color: #8000f6 !important;
}

.upgrade-btn:hover {
    color: #8000f6 !important;
    border-color: #8000f6 !important;
}

/**
* Dashboard
*/

.dashboard-content {
    height: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 10px;
    margin: 15px 3%;
}

.sider {
    background-color: #ffffff;
}

.content {
    background-color: #ffffff;
}

.dashboard-sider {
    height: 100%;
    background-color: #ffffff;
    padding: 10px;
    padding-top: 30px;
}

.sider-button {
    /* background: #EDEEF0; */
    border: none;
    border-radius: 8px;
    color: #333333 !important;
}

.sider-menu {
    text-decoration: none;
    border: none !important;
}

.sider-button.active {
    text-decoration: none;
    background-color: #EDEEF0;
    color: #333333;
}

.sider-button:hover {
    text-decoration: none;
    /* color: #333333; */
    font-weight: 400;
}

/*  */
/* Integration */
/*  */

.intergration {
    max-width: 30px;
}

.button-with-image {
    border-radius: 4px;
    background: #F4F4F4;
    /* Neutral/5 */
    border: 1px solid #D9D9D9;
    /* drop-shadow / button-secondary */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-with-image:hover:not([disabled]) {
    background: #E6E6E6;
    border-color: #333333;
    color: #333333;
    cursor: pointer;
}

.button-with-image[disabled] {
    background: #FAFAFA;
    border-color: #D9D9D9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.button-image {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

/*  */
/* Statsbot */
/*  */


.statbot-container {
    background: #F5F5F5;
    border-radius: 4px;
    margin: 25px 0px;
    padding: 10px 20px;
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.statbot-div {
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F;
}

.statbot-div-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.7em;
}

.statbot-div-img {
    height: 12px;
    margin-right: 0.7em;
    border-radius: 4px;
    opacity: 0.7;
}

.statbot-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.statbot-button:hover {
    text-decoration: none;
    color: #333333 !important;
    border-color: #333333 !important;
}


/* Variables */

.variables {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.001em;
    color: #4F4F4F;
}

.variables ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.variables li:hover {
    color: #383838;
    font-weight: bold;
    cursor: default;
}

.grid-container {
    display: grid;
    /* grid-template-rows: repeat(2, 1fr); */
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.list1 {
    grid-row: 1;
    grid-column: 1;
}

.list2 {
    grid-row: 1;
    grid-column: 2;
}

.list3 {
    grid-row: 2;
    grid-column: 1;
}

.list4 {
    grid-row: 2;
    grid-column: 2;
}

.list5 {
    grid-row: 3;
    grid-column: 1;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Billing */

@media screen and (max-width: 720px) {
    .billing-div {
        flex-direction: column-reverse !important;
    }

    .billing-div-1 {
        margin-bottom: 5px;
        border-right: 0px !important;   
    }

    .billing-div-2 {
        margin-left: 0px !important;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }

    .statbot-button {
        font-size: 12px;
        padding: 4px 8px;
    }
}

@media screen and (max-width: 530px) {
    .ant-switch-inner{
        display: none !important;
    }

    .dashboard-content {
        margin-left: 5px;
        padding-left: 0px;
    }
}

/**
* Utilities
*/

.m10 {
    margin: 10px;
}

.mt-1 {
    margin-top: 1rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mb-1 label {
    display: block;
    margin-bottom: 0.2rem; /* Add desired spacing between label and input */
}


.ant-btn-primary {
    background: #333333 !important;
    border-color: #333333 !important;
    color: white !important;
    height: 2.5rem;
}

.ant-btn-primary:hover {
    background: #333333 !important;
    border-color: #333333 !important;
}

.ant-btn-primary:focus {
    background: #ffffff !important;
    border-color: #333333 !important;
}


.ant-btn:focus {
    color: #333333 !important;
    border-color: #333333 !important;
}

.form-header {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 2rem 0;
}


.ant-menu-item-selected {
    background-color: #f5f5f5 !important;
}
